<template>
  <div>
    <form @submit="sendNew">
      <div class="mb-3">
        <label for="name_input" class="form-label">{{ $t("nameUser") }}</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.name"
          aria-describedby="name_help"
          placeholder="Nome do usuário"
        />
      </div>
      <div class="mb-3">
        <label for="email_input" class="form-label">{{
          $t("emailUser")
        }}</label>
        <input
          type="email"
          class="form-control"
          v-model="formInclude.email"
          aria-describedby="email_help"
          placeholder="E-mail do usuário"
        />
      </div>

      <div class="row">
        <div class="col-6 mb-3">
          <label for="password_input" class="form-label">{{
            $t("passUser")
          }}</label>
          <input
            type="password"
            class="form-control"
            v-model="password"
            aria-describedby="password_help"
            placeholder="Digite a senha - Mínimo 9 Digitos"
          />
        </div>
        <div class="col-6 mb-3">
          <label for="password1_input" class="form-label">{{
            $t("passrUser")
          }}</label>
          <input
            type="password"
            class="form-control"
            v-model="password1"
            aria-describedby="password1_help"
            placeholder="Repita a senha - Mínimo 9 Digitos"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-6 mb-3">
          <label for="nivel_input" class="form-label">{{
            $t("levelUser")
          }}</label>
          <!-- <input
          type="text"
          class="form-control"
          v-model="formInclude.nivel"
          aria-describedby="nivel_help"
        /> -->
          <select
            class="form-select"
            @change="formInclude.nivel = $event.target.value"
            v-model="formInclude.nivel"
          >
            <option value="9">ADMIN</option>
            <option value="1">Redator</option>
          </select>
        </div>
        <div class="col-6 mb-3">
          <label for="lang_input" class="form-label">{{ $t("status") }}</label>
          <select
            class="form-select"
            @change="formInclude.status = $event.target.value"
            v-model="formInclude.status"
          >
            <option value="1">Ativo</option>
            <option value="0">Inativo</option>
          </select>
        </div>
      </div>

      <div class="d-grid gap-2">
        <button
          class="btn btn-success bg-success bg-gradient col-6 mx-auto"
          type="submit"
        >
          {{ $t("enviar") }} {{ $t("newone") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: ["actionSend", "toastShow"],
  data: () => ({
    formInclude: {},
    password: "",
    password1: "",
  }),
  mounted() {},
  methods: {
    sendNew: function (event) {
      event.preventDefault();
      if (
        this.password &&
        this.password1 &&
        this.password == this.password1 &&
        this.password.length > 8
      ) {
        this.formInclude.password = this.password;
        this.actionSend(this.formInclude);
      } else {
        this.toastShow({ msg: "Senha Inválida", style: "danger" });
      }
    },
  },
};
</script>

<style>
</style>